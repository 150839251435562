<template>
    <TaskPageWrapper task-type="Request" @formDirtyChange="formDirty = $event"/>
    <ConfirmDialog ref="confirmDialog"/>
</template>

<script>
import {mapActions} from 'vuex';
import {TaskService} from '@/services/Task/TaskService';
import TaskPageWrapper from '@/components/TaskPages/TaskPageWrapper.vue';
import ConfirmDialog from '@/components/Modals/ConfirmDialog.vue';
import {REVOKED} from "@/domain/Entities/Task/taskStatuses";

export default {
    components: {
        ConfirmDialog,
        TaskPageWrapper,
    },
    data() {
        return {
            formDirty: false,
            loadingMessagesData: false,
            topLinks: [
                {title: 'Dashboard', routeName: 'ProjectDashboard'},
                {title: 'Tasks', routeName: 'TaskRequest'},
                {title: 'Documents', routeName: 'ProjectDocuments'},
                {title: 'Project Info', routeName: 'ProjectDetails'},
                {title: 'Activity', routeName: 'ProjectActivity'},
            ],
            task: {},
            key: 0,
            staticData: Object.seal({
                taskId: null,
            }),
        };
    },
    watch: {
        '$store.state.tasks.permissions.edit': {
            immediate: true,
            handler(to, from) {
                if (from && !to && this.task.status !== REVOKED) this.fetchData();
            },
        },
    },
    mounted() {
        this.staticData.taskId = this.$route.params.task_id;
        this.$nextTick(() => this.fetchData());
    },
    unmounted() {
        if (this.staticData.taskId) {
            this.unsubscribe('private-task.' + this.staticData.taskId);
        }
    },
    methods: {
        ...mapActions({
            subscribe: 'subscribe',
            getMessages: 'getMessages',
            unsubscribe: 'unsubscribe',
            getChatsData: 'getChatsData',
        }),

        fetchData() {
            this.$http.tasks
                .getTask({params: {task_id: this.$route.params.task_id}})
                .then(async (res) => {
                    await TaskService.checkTypeAndRedirect('Request documents', res.data.data.type, this.$route.params);
                    this.modal = false;
                    this.task = res.data.data;
                    this.key++;

                    this.$store.dispatch('fetchTaskPermissions', this.$route.params.task_id);

                    this.loadingMessagesData = true;

                    this.subscribe({
                        channel: 'task.' + this.$route.params.task_id,
                        event: '.task-chat',
                    });

                    this.getMessages({task_id: this.$route.params.task_id}).finally(
                        () => (this.loadingMessagesData = false),
                    );
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        this.$router.push({name: 'PermissionsError'});
                    }
                });
            this.getChatsData({task_id: this.$route.params.task_id});
        },
    },
};
</script>
